import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "id": "head-metadata",
  "title": "Looplex Legal Docs"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "looplex-legal-docs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#looplex-legal-docs",
        "aria-label": "looplex legal docs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Looplex Legal Docs`}</h1>
    <h2 {...{
      "id": "termos-do-produto",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#termos-do-produto",
        "aria-label": "termos do produto permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Termos do Produto`}</h2>
    <p>{`Os Termos do Produto contêm todos os termos e condições para o software e serviços online, bem como o acordo de nível de serviço (SLA) que descreve os compromissos de tempo de atividade e conectividade para nossos serviços.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/product/terms-of-use"
        }}>{`Termos de Uso e Licenciamento`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/product/service-level-agreements-SLA"
        }}>{`SLA`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/product/professional-services"
        }}>{`Serviços Profissionais`}</a></li>
    </ol>
    <h2 {...{
      "id": "faturamento",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#faturamento",
        "aria-label": "faturamento permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Faturamento`}</h2>
    <p>{`Políticas de faturamento, créditos promocionais e descontos, preços e planos.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/billing/pricing-policy"
        }}>{`Política de Preços`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/billing/fair-billing-policy"
        }}>{`Política de Faturamento`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/billing/service-credits-policy"
        }}>{`Política de Créditos de Serviço`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/billing/promotional-credits-policy"
        }}>{`Política de Créditos Promocionais`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.looplex.com.br/pricing/funcionalidades.html"
        }}>{`Planos de Assinatura`}</a></li>
    </ol>
    <h2 {...{
      "id": "privacidade-e-proteção-de-dados",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#privacidade-e-prote%C3%A7%C3%A3o-de-dados",
        "aria-label": "privacidade e proteção de dados permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Privacidade e Proteção de Dados`}</h2>
    <p>{`Políticas de privacidade, proteção de dados e contratos de operador de dados.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/data-protection/privacy-policy"
        }}>{`Política de Privacidade`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/data-protection/how-we-protect-your-data"
        }}>{`Como Protegemos os Seus Dados`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://looplex.com.br/docs/looplex-LGPD.pdf"
        }}>{`Guia LGPD`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.looplex.com.br/docs/looplex-data-protection-agreement.pdf"
        }}>{`Termo de Processamento de Dados`}</a></li>
    </ol>
    <h2 {...{
      "id": "guias",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#guias",
        "aria-label": "guias permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Guias`}</h2>
    <p>{`Guias e materiais de orientação sobre nossos produtos, regras de licenciamento e framework regulatório aplicável.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/guides/licensing-guide"
        }}>{`Guia de Licenciamento`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/guides/legal-documentation-summary"
        }}>{`Guia de Documentação Jurídica`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/guides/regulatory-framework"
        }}>{`Guia de Framework Regulatório`}</a></li>
    </ol>
    <h2 {...{
      "id": "marketplace",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#marketplace",
        "aria-label": "marketplace permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Marketplace`}</h2>
    <p>{`Regras sobre nosso marketplace, serviços especiais e contratos de desenvolvedor na plataforma Looplex.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/marketplace/store-terms-of-sale"
        }}>{`Termos de Uso da Loja`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/marketplace/expert-service-provider-agreement"
        }}>{`Termos de Participação no Marketplace de Serviços`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/marketplace/app-developer-agreement"
        }}>{`Contrato de Desenvolvedor`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/marketplace/referral-agreement"
        }}>{`Parceria para Intermediação de Venda do Sistema`}</a></li>
    </ol>
    <h2 {...{
      "id": "equipe-e-fornecedores",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#equipe-e-fornecedores",
        "aria-label": "equipe e fornecedores permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Equipe e Fornecedores`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/workforce/privacy-policy-for-cadidates"
        }}>{`Política de Privacidade para Candidatos`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/workforce/employee-and-supplier-documentation-policy"
        }}>{`Documentação de Colaboradores e Fornecedores`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.looplex.com.br/docs/looplex-codigoconduta.pdf"
        }}>{`Código de Conduta`}</a></li>
    </ol>
    <h2 {...{
      "id": "termos-e-políticas-complementares",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#termos-e-pol%C3%ADticas-complementares",
        "aria-label": "termos e políticas complementares permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Termos e Políticas Complementares`}</h2>
    <p>{`Políticas, termos de uso e notas de transparência complementares sobre produtos e serviços da Looplex.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/supplemental-terms/cookie-notice"
        }}>{`Aviso de Cookies`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/supplemental-terms/feature-removal-policy"
        }}>{`Política de Remoção de Funcionalidades`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/supplemental-terms/website-naming-convention-policy"
        }}>{`Política para Páginas de Documentação`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/supplemental-terms/lawsoft-clients-migration"
        }}>{`Termos da Oferta de Migração Lawsoft 2023`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/supplemental-terms/lawsoft-terms-of-use"
        }}>{`Termos de Uso e Licença do Lawsoft (legado)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/supplemental-terms/looplex-limited-public-license"
        }}>{`Licenciamento Biblioteca de Dados Abertos`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/supplemental-terms/cognitive-services-code-of-conduct"
        }}>{`Código de Conduta de Serviços Cognitivos`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/supplemental-terms/ai-transparency-note"
        }}>{`Nota de Transparência no uso de IA`}</a></li>
    </ol>
    <h2 {...{
      "id": "parcerias-e-programas",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#parcerias-e-programas",
        "aria-label": "parcerias e programas permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Parcerias e Programas`}</h2>
    <p>{`Encontre informações sobre programas de licenciamento especiais para uso experimental, acadêmico, governamental e programas de parcerias.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/partnerships-and-programs/beta-program-agreement"
        }}>{`Programa Looplex Beta`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/partnerships-and-programs/school-agreement"
        }}>{`Parceria Educacional`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/pt-BR/legal/partnerships-and-programs/open-inovation-terms"
        }}>{`Programa de Inovação Aberta`}</a></li>
    </ol>
    <h2 {...{
      "id": "central-de-segurança",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#central-de-seguran%C3%A7a",
        "aria-label": "central de segurança permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Central de Segurança`}</h2>
    <p>{`Saiba mais sobre como construímos nossos produtos e serviços com segurança, privacidade, conformidade e transparência em mente, bem como nosso compromisso de fornecer a você controle e transparência sobre seus dados.
(Em breve)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      